.card {
    .files-table {
        background-color: rgba(0, 0, 0, .03);


        .row-table {
            display: flex;
            border-top: 1px solid rgba(0, 0, 0, .125);

            .col-table {
                padding: 10px;

                .btn {
                    padding-left: 20px;
                    padding-right: 20px;
                    font-size: 17px;
                }
            }
        }
    }
}
