// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "files";


.dt-layout-full {
    width: 100%;
}

.dt-layout-table {
    margin-top: 10px;
}

.dt-input {
    margin-right: 5px;
    margin-left: 5px;
}

input {
    &.dt-input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-clip: padding-box;
        background-color: #f8fafc;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        color: #212529;
        font-size: .9rem;
        font-weight: 400;
        line-height: 1.6;
        padding: .375rem .75rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
}

select {
    &.dt-input {
        padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
        // stylelint-disable-next-line property-no-vendor-prefix
        -moz-padding-start: subtract($form-select-padding-x, 3px); // See https://github.com/twbs/bootstrap/issues/32636
        font-family: $form-select-font-family;
        @include font-size($form-select-font-size);
        font-weight: $form-select-font-weight;
        line-height: $form-select-line-height;
        color: $form-select-color;
        background-color: $form-select-bg;
        background-image: escape-svg($form-select-indicator);
        background-repeat: no-repeat;
        background-position: $form-select-bg-position;
        background-size: $form-select-bg-size;
        border: $form-select-border-width solid $form-select-border-color;
        @include border-radius($form-select-border-radius, 0);
        @include box-shadow($form-select-box-shadow);
        @include transition($form-select-transition);
        appearance: none;

        &:focus {
            border-color: $form-select-focus-border-color;
            outline: 0;
            @if $enable-shadows {
                @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
            } @else {
                // Avoid using mixin so we can pass custom focus shadow properly
                box-shadow: $form-select-focus-box-shadow;
            }
        }

        &[multiple],
        &[size]:not([size="1"]) {
            padding-right: $form-select-padding-x;
            background-image: none;
        }

        &:disabled {
            color: $form-select-disabled-color;
            background-color: $form-select-disabled-bg;
            border-color: $form-select-disabled-border-color;
        }

        // Remove outline from select box in FF
        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 $form-select-color;
        }
    }
}
